//Components

//Pages
@import "./general";

//custom scrollbar
::-webkit-scrollbar {
  width: 7px;
  height: 5px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #e6e6e6; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #cccccc; /* color of the scroll thumb */
  border-radius: 20px;
}

.sticky-Tab {
  position: sticky;
  background-color: #fff;
  margin-left: -32px;
  margin-right: -32px;
  padding-left: 32px;
  padding-right: 32px;
  z-index: 1101;
  &.no-margin{
    margin: 0;
  }
}
